import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import { connect } from "react-redux";
import { autenticar } from "./../actions/AutenticacaoActions";

import Home from "../paginas/externas/Home";

import Contato from "../paginas/externas/Contato";
import { Cadastro } from "../paginas/externas/Cadastro";
import Login from "../paginas/externas/LoginCliente";
import Inicio from "../paginas/internas/Painel/Inicio";
import Pagamento from "../paginas/externas/Pagamento";
import Transacao from "../paginas/externas/Transacao";

import PrivateRoute from "./PrivateRoute";
import Forbiden from "../paginas/externas/Forbiden";

class Routes extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/apresentacao" component={Home} />
        <Route exact path="/contato" component={Contato} />
        <Route path="/cadastro" component={Cadastro} />
        <Route exact path="/login-cliente" component={Login} />
        <Route exact path="/acesso-proibido" component={Forbiden} />
        <Route exact path="/pagamento" component={Pagamento} />
        <Route exact path="/transacao" component={Transacao} />

        <PrivateRoute path="/painel/inicio" component={Inicio} />
        <PrivateRoute
          path="/painel/clientes/listar"
          component={() => <h1>Oi</h1>}
        />
      </Switch>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    autenticacao: state.autenticacao.autenticado,
  };
};

export default connect(mapStateToProps, { autenticar })(Routes);
